import React from 'react'
import SEO from '../components/App/SEO'
import Layout from '../components/App/Layout';
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import DropDownButton from '../components/App/DropDownButton'
import { isMobile } from 'react-device-detect'
import { graphql } from 'gatsby';

const dl = ({data}) => {

    return (
      <Layout env={data.site.siteMetadata.env}>
        <Navbar />
        <SEO
          postTitle="Download | BlueMail App"
          postDescription="BlueMail is available as a free download on your Android or iOS mobile device and also on your Windows, Mac, & Linux devices."
          postImage="/img/BlueMail_Desktop_Home.png"
          postURL="dl"
          postSEO
        />
        <div className="container pt-120 pb-100 center-text">
          <div style={{ background: '#FAFAFA' }}>
            <div className="row">
              <div className="col-12">
                <h1 className="mb-30">
                  Download BlueMail Today
                </h1>
                <p className="mb-30">
                  BlueMail is a secure, fast, easy to use, cross platform email
                  app which is capable of handling an unlimited number of email
                  accounts from any email provider. With unified folders,
                  people-centric option, sharing emails, clusters, and a truly
                  remarkable user interface and experience, BlueMail will make
                  you love email again.
                </p>
              </div>
            </div>
          </div>
          <div style={{maxWidth: '353px', margin: '30px auto 50px auto'}}>
            <DropDownButton />
          </div>
          
          <div className="row" style={{alignItems: "center"}}>
            <div className="col-lg-10 col-sm-12">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <img
                  className="family-device-img"
                  src={`/img/dl/BlueMail-Device-Family-Windows-Mac-Android-iOS.png`}
                  alt="BlueMail Devices: Windows, Android, iOS, Gear, and iWatch"
                />
              </div>
            </div>
            <div className="col-lg-2 col-sm-12">
              {!isMobile && <div className="qr-wrapper">
                            <img
                                src={`/img/dl/bm-qrcode_1.png`}
                                alt="QR code"
                            />
                            <p className="qr-text">
                                Scan with your phone to download
                            </p>
                            </div>
              }
            </div>
          </div>
        </div>
        <Footer />
      </Layout>
    )
}

export default dl

export const query = graphql`
query DlPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
